<template>
  <div class="content">
<!--    <WidgetsDropdown />-->
    <CRow class="main-cards-row">
      <CCol sm="4" lg="3">
        <CWidgetDropdown color header="Competitions" style="color: Black !important;">
          <template #default>
            <img
              :src="require(`../../../../../../public/img/icons/competitions.svg`)"
              :alt="`competitions logo`"
              class="c-sidebar-nav-icon zq-sidebar-custom-logo main-card-logo"
            />
          </template>
          <template #footer>
            <div class="mb-3 main-card">
              <CRow class="mt-0 mx-4">
                <CCol class="font-2xl">
                  {{ competitions.active.length }}
                  <router-link :to="{ name: `Competitions`, params: {status: 'Active'} }" class="ml-2">Live</router-link>
                </CCol>
              </CRow>
              <CRow class="mt-0 mx-4">
                <CCol class="font-2xl">
                  {{ competitions.ready.length }}
                  <router-link :to="{ name: `Competitions`, params: {status: 'Ready'} }" class="ml-2">Ready</router-link>
                </CCol>
              </CRow>
              <CRow class="mt-0 mx-4">
                <CCol class="font-2xl">
                  {{ competitions.finalised.length }}
                  <router-link :to="{ name: `Competitions`, params: {status: 'Finalised'} }" class="ml-2">Finalised</router-link>
                </CCol>
              </CRow>
            </div>
          </template>
        </CWidgetDropdown>
      </CCol>

      <CCol sm="4" lg="3" style="margin-right: -15px;">
        <CWidgetDropdown color header="Achievements" style="color: Black !important;">
          <template #default>
            <img
              :src="require(`../../../../../../public/img/icons/jorneys.svg`)"
              :alt="`competitions logo`"
              class="c-sidebar-nav-icon zq-sidebar-custom-logo main-card-logo"
            />
          </template>
          <template #footer>
            <div class="mb-3 main-card">
              <CRow class="mt-0 mx-4">
                <div class="font-2xl">
                  {{ achievements.activeTotal }}
                  <router-link :to="{ name: `Achievements` }" class="ml-2">Live</router-link>
                </div>
              </CRow>
              <CRow class="mt-0 mx-4">
                <div class="font-2xl">
                  {{ achievements.readyTotal }}
                  <router-link :to="{ name: `Achievements` }" class="ml-2">Ready</router-link>
                </div>
              </CRow>
              <CRow class="mt-0 mx-4">
                <div class="font-2xl">
                  {{ achievements.finishedTotal }}
                  <router-link :to="{ name: `Achievements` }" class="ml-2">Finished</router-link>
                </div>
              </CRow>
            </div>
          </template>
        </CWidgetDropdown>
      </CCol>
    </CRow>
    <CCard class="dashboard-calendar">
      <CCardBody>
        <CCardHeader style="font-size: 21px;">
          Competitions Calendar
          <SwitchRow
            :label="'Week period:'"
            :toolTipText="''"
            :isShowTooltip="false"
            :valueProp="isShowWeek"
            :starting-day-of-week="startingDayOfWeek"
            @swipeHandler="swipeDisplayPeriodHandler"
            @updateHandler="displayPeriodUpdate"
          />
        </CCardHeader>
        <calendar-view
          :items="calendarItems"
          :show-date="showDate"
          :time-format-options="{ hour: 'numeric', minute: '2-digit' }"
          :class="themeClasses"
          :starting-day-of-week="startingDayOfWeek"
          :enable-drag-drop="false"
          :displayPeriodUom="displayPeriod"
          @click-item="clickEvent"
        >
          <calendar-view-header slot="header" slot-scope="t" :header-props="t.headerProps" @input="setShowDate" />
        </calendar-view>
      </CCardBody>
    </CCard>
    <CCard v-if="dataSetsReady">
      <CCardBody>
        <CChartBar
          v-for="(set, label) in dataSetsByLabels"
          :key="label"
          :labels = "labels[label]"
          :datasets = "set"
          :options="{
            maintainAspectRatio: false,
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                }
              }],
            }
          }"
          style="height: 300px"
        />
      </CCardBody>
    </CCard>

    <AchievementTable :activeAchievements="achievements.active"/>
    <CompetitionsTable :activeCompetitions="competitions.active"/>
  </div>
</template>
<script>
// import WidgetsDropdown from '@/views/widgets/WidgetsDropdown'
import routerBreadcrumbs from "@/router/breadcrumb/routerBreadcrumbs";
import AchievementTable from "./AchievementTable";
// import MembersBarChart from "@/views/ziqni/Dashboard/details/Main/MembersBarChart";
import CompetitionsTable from "@/views/ziqni/Dashboard/details/Main/CompetitionsTable";
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar"
import {mapActions, mapGetters} from 'vuex'
import SwitchRow from '@/shared/components/SwitchRow';
import axios from "axios";
import { CChartBar } from '@coreui/vue-chartjs';
import {cloneDeep} from 'lodash'
require("vue-simple-calendar/static/css/default.css")
require("vue-simple-calendar/static/css/holidays-us.css")

export default {
  // name: "main",
  components: {
    CompetitionsTable,
    // MembersBarChart,
    // WidgetsDropdown,
    AchievementTable,
    CalendarView,
    CalendarViewHeader,
    SwitchRow,
    CChartBar,
  },
  data() {
    return {
      showDate: new Date(),
      startingDayOfWeek: 1,
      themeClasses: {
        'theme-default': true,
      },
      calendarItems: [],
      displayPeriod: 'month',
      isShowWeek: false,
      competitions: {
        active: [],
        ready: [],
        finalised: [],
      },
      achievements: {
        active: [],
        ready: [],
        finished: [],
        activeTotal: 0,
        readyTotal: 0,
        finishedTotal: 0,
      },
      dataSetsReady: false,
      labels: {},
      dataSetsByLabels: {},
    }
  },
  created() {
    this.initialize()
  },
  computed: {
    ...mapGetters('achievements', ['totalRecords']),
  },
  methods: {
    ...mapActions('competitions', ['handleGetCompetitionsByQuery']),
    ...mapActions('achievements', ['handleGetAchievementsByQuery']),
    async initialize() {
      routerBreadcrumbs(this.$router.currentRoute);

      const competitions = await this.handleGetCompetitionsByQuery({
        queryRequest: {
          "must": [
            {
              "queryField": "status",
              "queryValues": ['Active', 'Ready', 'Finalised']
            }
          ],
          limit: 9999,
        }
      });
      if (competitions.length) {
        this.setCompetitionsByStatus(competitions);
        this.setCalendarItems(competitions);
      }
      this.getAchievementsByStatus();

      await this.getChartData();
    },
    async getChartData() {
      const token = localStorage.getItem('vue-token');
      const hostname = window.location.hostname;
      const isStagingDev = hostname.includes('127.0.0.1');
      const isStaging = hostname.split('.').includes('staging');

      const baseUrl = isStagingDev || isStaging
        ? `https://api.staging.ziqni.io`
        : `https://api.ziqni.com`;

      try {
        const dashboardResponseData = await axios.get(`${baseUrl}/dashboard`, {
          headers: {"Authorization" : `Bearer ${token}`}
        });

        if (!dashboardResponseData.data.results) return;

        const chartData = dashboardResponseData.data.results[0];
        const dataSets = chartData.dataSets;
        this.labels = chartData.labels;

        for (const label in this.labels) {
          this.dataSetsByLabels[label] = [];
          for (const key in dataSets) {
            if (dataSets[key].labels === label) {
              delete dataSets[key].labels;
              this.dataSetsByLabels[label].push(dataSets[key]);
            }
          }
        }
      } catch (e) {
        console.warn(e);
      }

      for (const key in this.dataSetsByLabels) {
        this.dataSetsByLabels[key].forEach(set => {
          if (set.label.includes('bet') && set.label.includes('points_sum')) {
            set.backgroundColor = '#5eb420'
          } else if (set.label.includes('bet') && set.label.includes('sourceValue_sum')) {
            set.backgroundColor = '#afd882'
          } else if (set.label.includes('win') && set.label.includes('points_sum')) {
            set.backgroundColor = '#a98ff9'
          } else if (set.label.includes('win') && set.label.includes('sourceValue_sum')) {
            set.backgroundColor = '#805cf4'
          } else if (set.label.includes('events_action_sourceValue_sum')) {
            set.backgroundColor = '#5eb420'
          } else if (set.label.includes('events_action_points_value_count')) {
            set.backgroundColor = '#afd882'
          } else if (set.label.includes('events_action_points_sum')) {
            set.backgroundColor = '#a98ff9'
          } else if (set.label.includes('events_action_sourceValue_value_count')) {
            set.backgroundColor = '#805cf4'
          } else {
            set.backgroundColor = '#007DFB'
          }
        })
      }

      if (Object.keys(this.dataSetsByLabels).length) {
        this.dataSetsReady = true;
      }
    },
    getAchievementsByStatus() {
      this.handleGetAchievementsByQuery({
        queryRequest: {
          "must": [
            {
              "queryField": "status",
              "queryValues": ['Active']
            }
          ],
          limit: 50,
        }
      }).then(activeAchievements => {
        if (activeAchievements.length) {
          this.achievements.activeTotal = cloneDeep(this.totalRecords);
          this.achievements.active = activeAchievements;
        }
      });

      this.handleGetAchievementsByQuery({
        queryRequest: {
          "must": [
            {
              "queryField": "status",
              "queryValues": ['Ready']
            }
          ],
          limit: 1,
        }
      }).then(readyAchievements => {
        if (readyAchievements.length) {
          this.achievements.readyTotal = cloneDeep(this.totalRecords);
          this.achievements.ready = readyAchievements;
        }
      });

      this.handleGetAchievementsByQuery({
        queryRequest: {
          "must": [
            {
              "queryField": "status",
              "queryValues": ['Finished']
            }
          ],
          limit: 1,
          skip: 0
        }
      }).then(finishedAchievements => {
        if (finishedAchievements.length) {
          this.achievements.finishedTotal = cloneDeep(this.totalRecords);
          this.achievements.finished = finishedAchievements;
        }
      });
    },
    setCompetitionsByStatus(competitions) {
      competitions.forEach(competition => {
        switch (competition.status) {
          case 'Active':
            this.competitions.active.push(competition);
            break;
          case 'Ready':
            this.competitions.ready.push(competition);
            break;
          case 'Finalised':
            this.competitions.finalised.push(competition);
            break;
        }
      })
    },
    setCalendarItems(competitions) {
      competitions.forEach(item => {
        let itemClass = '';
        switch (item.status) {
          case 'Active':
            itemClass = 'competition-active';
            break;
          case 'Ready':
            itemClass = 'competition-ready';
            break;
          case 'Finalised':
            itemClass = 'competition-finalised';
            break;
        }

        this.calendarItems.push({
          id: item.id,
          title: item.name,
          startDate: new Date(item.scheduledStartDate.getTime() - item.scheduledStartDate.getTimezoneOffset() * 60000).toISOString(),
          endDate: new Date(item.scheduledEndDate.getTime() - item.scheduledEndDate.getTimezoneOffset() * 60000).toISOString(),
          classes: ['competition', itemClass],
        })
      })
    },
    swipeDisplayPeriodHandler(direction) {
      this.isShowWeek = direction === 'right';
    },
    displayPeriodUpdate(val) {
      if (val) {
        this.displayPeriod = 'week';
      } else {
        this.displayPeriod = 'month';
      }
    },
    setShowDate(d) {
      this.showDate = d;
    },
    clickEvent(val) {
      this.$router.push({
        name: 'PreviewCompetition',
        params: {
          id: val.id,
        },
      })
    },
    color(value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    }
  },
}
</script>

<style lang="scss">
.content {
  background: var(--zq-sub-bg);
  border-top: 1px solid var(--zq-content-border-color);
}

.main-cards-row .main-card {
  height: 6.5rem;
}

.main-cards-row .text-value-lg {
  position: relative;
  opacity: 0.7;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #282f37;
  margin-right: 16px;
}

.main-cards-row .card-body {
  padding-top: 0.5rem;
  //padding-bottom: 1rem !important;
}

.main-cards-row .card,
.card {
  border-radius: 0.75rem;
}

.card .card-header {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.main-cards-row .main-card-logo {
  background-color: #e74a39;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40px;
  flex: 0 0 40px;
  height: 40px;
  padding: 8px;
  border-radius: 12px;
}

.main-cards-row .bottom-right {
  position: absolute;
  right: 0.5rem;
  bottom: 0.35rem;
}

#all-charts .text-value-lg:before {
  content: " \2191";
  color: white;
}

.dashboard-calendar {
  .card-header {
    display: flex;
    justify-content: space-between;
    .switch-row {
      flex-grow: 1;
      align-items: center;
      justify-content: flex-end;
      .switch-row-wrap {
        padding: 0;
        width: 50px;
      }
      .col-xl-3 {
        width: 50px;
        max-width: 50px;
        padding: 0;
      }
      .col-xl-2 {
        width: 120px;
        max-width: 120px;
      }
    }
  }
  .card-body {
    height: 800px;
  }
  .cv-wrapper {
    height: 696px;
    max-height: 696px;
    min-height: 696px;
  }
  .calendar-controls {
    margin-right: 1rem;
    min-width: 14rem;
    max-width: 14rem;
  }
  .calendar-parent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: hidden;
    max-height: 80vh;
    background-color: white;
  }
  .cv-wrapper.period-month.periodCount-2 .cv-week,
  .cv-wrapper.period-month.periodCount-3 .cv-week,
  .cv-wrapper.period-year .cv-week {
    min-height: 6rem;
  }
  .competition {
    border-radius: 0;
    border-color: #ffffff;
    color: #ffffff;
    font-size: 0.85em;
    padding: 3px;
    cursor: pointer;
    &-active {
      background-color: #6CB420;
    }
    &-ready {
      background-color: #007DFB;
    }
    &-finalised {
      background-color: #805CF4;
    }
  }
}
</style>
